import * as React from 'react';
import '@progress/kendo-date-math/tz/MST7MDT'
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AssignedUser, ClaimSlotsResponse, useCheckAutoSuggestedSlot, useCheckScheduleStatus, useScheduledSlots } from '../../../api/claim';
import { Calendar } from './calendar';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { Slot } from '../../../types/Slot';
import { useCurrentUser } from '../../../hooks/user-current-user.hook';
import { AddAvailabilityPayload, useAddAvailabilitySlots, useScheduleDeleteHomeownerSlot, } from '../../../api/homeowner';
import { useAssignInspectorQB, useInspectors, useScheduleDeleteInspectorSlot, } from '../../../api/inspector';
import { Alert } from './delete-dialog';

import { toast } from 'react-toastify'
import { IInspector } from '../../../models/scheduler/inspector';
import { SLOT_TYPES, TIME_ZONE } from '../../../constants';
import { convertToTimezone, convertToTimezoneWithDateString } from '../../../utils';
import SingleDropdown from '../../../components/single-select-dropdown';

dayjs.extend(utc);
dayjs.extend(timezone);

interface UserItemProps {
    title: string;
    data: string
}

export function UserItem({ title, data }: UserItemProps) {
    return (
        <div className='d-flex flex-column'>
            <label>
                {title?.toUpperCase()}
            </label>
            <p style={{ fontWeight: 'bold' }}>
                {data}
            </p>
        </div>
    );
}




function SelectedSlotToSchedule({
    selectedSlot,
    onSelectInspector,
    inspectorIdError,
    selectedInspector,
    inspectors }: {
        selectedSlot: Slot;
        onSelectInspector: (inspectorId: string, inspector: any) => void;
        inspectorIdError: string;
        inspectors: IInspector[];
        selectedInspector: any;
    }) {


    const formattedInspectorsForDropdown = React.useMemo(() => {
        return inspectors.map((inspector) => ({ text: inspector.inspector_Name, value: inspector.userID?.toString() }))
    }, [inspectors])

    const [selectedInspectorId, setSelectedInspectorId] = React.useState('')



    return <div className='selectedSlots' >

        <SingleDropdown
            className="customDropdown"
            id="inspector"
            onchange={(inspectorId: string, inspector: any) => {
                onSelectInspector(inspectorId, inspector);
                setSelectedInspectorId(inspectorId)
            }}
            dataitems={formattedInspectorsForDropdown}
            isDef={selectedInspector}
            value={selectedInspectorId || selectedInspector?.value || "-1"}
        />

        <div>{dayjs.utc(selectedSlot.start).tz(TIME_ZONE).format('dddd, MMMM DD, YYYY')}</div>
        <div style={{ borderWidth: "0 0 1px", borderStyle: 'solid', borderColor: 'black', height: 22 }}>{dayjs.utc(selectedSlot.start).tz(TIME_ZONE).format('hh:ss A')}</div>
        <div style={{ borderWidth: "0 0 1px", borderStyle: 'solid', borderColor: 'black', height: 22 }}>{dayjs.utc(selectedSlot.end).tz(TIME_ZONE).format('hh:ss A')}</div>
        {inspectorIdError && <span style={{ color: 'red' }}>{inspectorIdError}</span>}
    </div>
}



// const addInspectors = (inspectors: IInspector[], assignedUsers: { [key: string]: AssignedUser }): { [key: string]: AssignedUser } => {
//     return inspectors.reduce((updatedAssignedUsers: { [key: string]: AssignedUser }, inspector) => {
//         updatedAssignedUsers[inspector.userID + "Inspector"] = {
//             scheduledUserId: inspector.userID,
//             scheduledUserName: inspector.inspector_Name,
//             schedulerGroupName: <div className='d-flex flex-column align-items-center'>
//                 <span className='fw-bold'>{inspector.inspector_Name}</span>
//                 <div><small className="text-secondary">Assigned: 0</small></div>
//             </div>,
//             userType: "Inspector",
//             scheduledUserIdForCalendar: inspector.userID + "Inspector"
//         }
//         return updatedAssignedUsers
//     }, { ...assignedUsers })

// }


export type ScheduleClaimHandler = {
    reloadCalendar: () => void
}



const handleHomeownerBackupOfSlot = (backupSlot: Slot, slotToBackup: Slot) => {
    if (backupSlot?.id === slotToBackup.id || slotToBackup.statusCode === SLOT_TYPES.temp) {
        return backupSlot
    }
    return slotToBackup
}


export const ScheduleClaimProfile = React.forwardRef<ScheduleClaimHandler, { claim: Scheduler; claimStatus: string; onAddOrDeleteSchedule: () => void }>(({ claim, onAddOrDeleteSchedule, claimStatus }, ref) => {

    const [selectedSlot, setSelectedSlot] = React.useState<Slot>()
    const { userID, userName } = useCurrentUser()
    const [slots, setSlots] = React.useState<{ [key: string]: Slot }>({})
    const [scheduledUsers, setScheduledUsers] = React.useState<{ [key: string]: AssignedUser }>({})
    const homeownerSlots = React.useRef<{ [key: string]: Slot }>()
    const { data: inspectors, isLoading: isLoadingInspectors } = useInspectors({
        variables: { claimID: claim?.claimID }
    })

    const { mutate: checkAutoSuggestedSlot, isPending: isPendingCheckAutoSuggestedSlot } = useCheckAutoSuggestedSlot()

    const autoSuggestLoaded = React.useRef(false)

    const { mutate: fetchScheduledSlots, isPending: isLoadingScheduledSlots } = useScheduledSlots({
        onSuccess: (response: ClaimSlotsResponse) => {
            setSlots(response.slots);
            setScheduledUsers(response.assignedUsers)
            homeownerSlots.current = response.homeownerSlots
            setSelectedSlot(null)
            handleAutoSuggestedSlot(response.slots)
        }
    })

    const selectedStartAndEndDatesRef = React.useRef<{ start: string, end: string }>({ start: null, end: null })


    const backupInspSlot = React.useRef<Slot>()
    const backupTempHomeownerSlot = React.useRef<Slot>()


    const getSlots = (claimId: number) => {
        backupSlot.current = null;
        tempHomeownerSlot.current = null;
        backupTempHomeownerSlot.current = null;
        backupInspSlot.current = null;

        if (selectedStartAndEndDatesRef.current.start && selectedStartAndEndDatesRef.current.end && !isLoadingInspectors) {
            fetchScheduledSlots({ claimId, startDate: selectedStartAndEndDatesRef.current.start, endDate: selectedStartAndEndDatesRef.current.end })
        }
    }

    const backupSlot = React.useRef<Slot>()
    const tempHomeownerSlot = React.useRef<Slot>()






    const handleSelectSlot = ({ start, end, resource, lastSlots, title }: { title?: string; start: Date; end: Date; resource?: AssignedUser, lastSlots?: { [key: string]: Slot } }) => {
        const isPrevDay = convertToTimezone(start).isBefore(convertToTimezone(new Date()), 'day')
        if (isPrevDay) return

        const startDateISOString = dayjs(start).toISOString()

        const slot: Slot = {
            id: startDateISOString + resource.scheduledUserId + resource.userType,
            title: title || "Selected Slot",
            start,
            end,
            statusCode: SLOT_TYPES.temp,

            ...resource,
        }

        if ((selectedSlot?.id === slot.id || tempHomeownerSlot.current?.id === slot.id) && selectedSlot.statusCode === SLOT_TYPES.temp) {
            return
        }

        const slotsToUpdate = (lastSlots || slots)
        const prevSlots = { ...slotsToUpdate }
        if (selectedSlot) {
            if (tempHomeownerSlot.current) {
                delete prevSlots[tempHomeownerSlot.current.id]
                tempHomeownerSlot.current = null
            }
            delete prevSlots[selectedSlot.id]
        }

        setSelectedSlot(slot)
        if (resource.userType === "Inspector") {
            setSelectedInspector({ text: slot.scheduledUserName, value: slot.scheduledUserId?.toString() })
            setInspectorIdError("")
        }
        else {
            setSelectedInspector(null)
        }

        const lastBackupSlot = backupSlot.current


        if (slot.userType === "Inspector" && !!slotsToUpdate[slot.id]) {
            backupSlot.current = slotsToUpdate[slot.id]
        }
        else {
            backupSlot.current = null
        }

        if (lastBackupSlot) {
            prevSlots[lastBackupSlot.id] = lastBackupSlot
        }


        const lastBackupTempHomeownerSlot = backupTempHomeownerSlot.current
        backupTempHomeownerSlot.current = null

        if (slot.userType === "Homeowner" && !!slotsToUpdate[slot.id]) {
            backupTempHomeownerSlot.current = handleHomeownerBackupOfSlot(lastBackupTempHomeownerSlot, slotsToUpdate[slot.id])
        }




        if (slot.userType === 'Inspector') {
            const homeownerResource: AssignedUser = {
                scheduledUserId: claim.homeowner_ID,
                scheduledUserName: claim.homeOwner,
                userType: "Homeowner",
                scheduledUserIdForCalendar: `${claim.homeowner_ID}Homeowner`,
            }
            tempHomeownerSlot.current = {
                id: startDateISOString + homeownerResource.scheduledUserId + homeownerResource.userType,
                title: title || "Selected Slot",
                start,
                end,
                statusCode: SLOT_TYPES.temp,
                ...homeownerResource,
            }


            if (!!slotsToUpdate[tempHomeownerSlot.current.id]) {
                backupTempHomeownerSlot.current = handleHomeownerBackupOfSlot(lastBackupTempHomeownerSlot, slotsToUpdate[tempHomeownerSlot.current.id])
            }



            prevSlots[tempHomeownerSlot.current.id] = tempHomeownerSlot.current


        }



        if (lastBackupTempHomeownerSlot && tempHomeownerSlot.current?.id !== lastBackupTempHomeownerSlot?.id) {
            prevSlots[lastBackupTempHomeownerSlot.id] = lastBackupTempHomeownerSlot;
        }

        prevSlots[slot.id] = slot
        setSlots(prevSlots)
    }




    const handleAutoSuggestedSlot = (lastSlots: { [key: string]: Slot; }) => {

        checkAutoSuggestedSlot({ claimId: claim?.claimID },
            {
                onSuccess: (response) => {
                    const resource: AssignedUser = {
                        scheduledUserId: response.inspectorID,
                        scheduledUserName: response.inspector,
                        userType: 'Inspector',
                        scheduledUserIdForCalendar: response.inspectorID + 'Inspector',
                    }
                    setSelectedSlot(null)
                    const start = convertToTimezoneWithDateString(response.slotFrom).toDate();
                    const end = convertToTimezoneWithDateString(response.slotTo).toDate();
                    handleSelectSlot({ start, end, resource, lastSlots, title: "Recommended" })
                    autoSuggestLoaded.current = true

                }
            }
        );
    }


    const { mutate: deleteScheduledHomeownerSlot, isPending: isPendingDeleteScheduledHomeownerSlot } = useScheduleDeleteHomeownerSlot()
    const { mutate: deleteScheduledInspectorSlot, isPending: isPendingDeleteScheduledInspectorSlot } = useScheduleDeleteInspectorSlot()

    const slotToDelete = React.useRef<Slot>()

    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

    const handleDeleteSlot = (slot: Slot) => {

        if (slot.statusCode === SLOT_TYPES.temp) {
            const slotsToDelete = { ...slots }
            delete slotsToDelete[selectedSlot.id]
            if (backupSlot.current) {
                slotsToDelete[backupSlot.current.id] = backupSlot.current
                backupSlot.current = null
            }

            if (tempHomeownerSlot.current) {
                delete slotsToDelete[tempHomeownerSlot.current.id]
                tempHomeownerSlot.current = null
            }

            if (backupTempHomeownerSlot.current) {
                slotsToDelete[backupTempHomeownerSlot.current.id] = backupTempHomeownerSlot.current
                backupTempHomeownerSlot.current = null
            }

            setSelectedSlot(undefined)
            setSlots(slotsToDelete)
            return
        }

        slotToDelete.current = slot
        setShowDeleteDialog(true)
    }



    const deleteSavedSlot = () => {
        setShowDeleteDialog(false);
        const slot = slotToDelete.current
        const slotsToDelete = { ...slots }

        const handleDeleteSuccess = () => {
            delete slotsToDelete[slot.id]
            setSlots(slotsToDelete)
            slotToDelete.current = null
            getSlots(claim.claimID);
            onAddOrDeleteSchedule?.()
        }

        const apiActionToDeleteSlot = slot.userType?.toLowerCase() === "homeowner" ? deleteScheduledHomeownerSlot : deleteScheduledInspectorSlot

        apiActionToDeleteSlot({ claimNumber: claim.claimNo, slotId: slot.slotId, inspectorId: slot.scheduledUserId, userId: userID, userName, homeownerId: slot.scheduledUserId, }, {
            onSuccess: () => handleDeleteSuccess()
        })
    }

    const { mutate: checkSlotStatus, isPending: isLoadingCheckSlotStatus } = useCheckScheduleStatus()

    const { mutate: addAvailability, isPending: isPendingAddAvailability } = useAddAvailabilitySlots({
        onSuccess: () => {
            getSlots(claim.claimID);
            setSelectedSlot(null)
            onAddOrDeleteSchedule?.()
        }
    })

    const [statusAlertOptions, setStatusAlertOptions] = React.useState<{ show: boolean; content: string }>({ show: false, content: '' })

    const handleSchedule = () => {

        if (!selectedInspector || (selectedInspector && selectedInspector.value === "-1")) {
            setInspectorIdError("Please select an inspector")
            return
        }


        checkSlotStatus({
            claimId: claim.claimID, homeownerId: claim.homeowner_ID, fromDate: selectedSlot.start.toISOString(), toDate: selectedSlot.end.toISOString(), inspectorId: Number(selectedInspector.value)
        }, {
            onSuccess: (response) => {
                if (response.homeownerStatusCode === SLOT_TYPES.scheduled && response.inspectorStatusCode === SLOT_TYPES.scheduled) {
                    const content = "There are already scheduled slots for both homeowner and inspector. Do you want to continue?"
                    setStatusAlertOptions({ show: true, content })
                    return
                }

                if (response.homeownerStatusCode === SLOT_TYPES.scheduled) {
                    const content = "There is already scheduled slot for homeowner. Do you want to continue?"
                    setStatusAlertOptions({ show: true, content })
                    return
                }

                if (response.inspectorStatusCode === SLOT_TYPES.scheduled) {
                    const content = "There is already scheduled slot for inspector. Do you want to continue?"
                    setStatusAlertOptions({ show: true, content })
                    return
                }

                handleAddAvailability()
            }
        })

    }

    const { mutate: assignInspectorToQB } = useAssignInspectorQB()


    const handleAddAvailability = () => {

        if (!selectedInspector || (selectedInspector && selectedInspector.value === "-1")) {
            setInspectorIdError("Please select an inspector")
            return
        }

        const selectedInspectorObj = inspectors.find(inspector => inspector.userID === Number(selectedInspector.value))

        if (!!selectedInspectorObj.inspectorQuickBaseId && !!claim.keyStoneNo) {
            const params = {
                taskID: claim.taskID ? Number(claim.taskID) : 0,
                assignmentNumber: claim.keyStoneNo,
                inspectorQbaseId: selectedInspectorObj.inspectorQuickBaseId,
                inspectorName: selectedInspectorObj.inspector_Name,
                scheduledOn: `${convertToTimezone(selectedSlot.start).format('MM/DD/YYYY')} (${convertToTimezone(selectedSlot.start).format('hh:mmA')} - ${convertToTimezone(selectedSlot.end).format('hh:mmA')})`,
                scheduledStartDateTime: convertToTimezone(selectedSlot.start).format(),
                scheduledEndDateTime: convertToTimezone(selectedSlot.end).format(),
                claimId: claim.claimID,
                claimNumber: claim.claimNo,
                homeownerEmail: claim.homeowner_Email,
                homeownerPhone: claim.homeOwner_Phone,
                homeownerName: claim.homeOwner
            }
            assignInspectorToQB(params)
        }

        const payload: AddAvailabilityPayload[] =
            [{ fromDateTime: selectedSlot.start.toISOString(), toDateTime: selectedSlot.end.toISOString(), claimID: claim.claimID, homeOwnerID: claim.homeowner_ID, slotStatusCode: SLOT_TYPES.scheduled, schedulerID: userID, inspectorID: Number(selectedInspector.value) }]
        addAvailability({ payload, params: { claimNumber: claim.claimNo } })
    }


    const [selectedInspector, setSelectedInspector] = React.useState<any>()



    const [inspectorIdError, setInspectorIdError] = React.useState<string>("")

    const handleSelectInspector = (inspectorId: string, inspector: any) => {

        handleSelectSlot({ start: selectedSlot.start, end: selectedSlot.end, resource: { userType: "Inspector", scheduledUserId: Number(inspectorId), scheduledUserIdForCalendar: inspectorId + "Inspector", scheduledUserName: inspector.text, }, title: "Selected Slot" })

        setSelectedInspector(inspector)
        setInspectorIdError("")
    }



    React.useImperativeHandle(ref, () => {
        return {
            reloadCalendar: () => {
                setSelectedSlot(null)
                getSlots(claim.claimID)
            }
        };
    }, [isLoadingInspectors, slots, getSlots, fetchScheduledSlots, selectedInspector, selectedSlot]);

    return (
        <div>
            <div className='claim-profile-calendar'>
                <LoadingIndicator isLoading={isPendingDeleteScheduledHomeownerSlot || isPendingDeleteScheduledInspectorSlot || isLoadingScheduledSlots || isLoadingInspectors || isLoadingCheckSlotStatus} />
                {!isLoadingInspectors && <Calendar claimStatus={claimStatus} selectedSlot={selectedSlot} source='claim' onSelectSlot={handleSelectSlot}
                    onChangeDate={(start, end) => {
                        selectedStartAndEndDatesRef.current = { start, end }
                        getSlots(claim.claimID)
                    }}
                    onDelete={handleDeleteSlot}
                    slots={slots}
                    onlyShowDay
                    group={{
                        resources: ["Persons"],
                        orientation: 'horizontal',

                    }}

                    resources={[
                        {
                            name: "Persons",
                            data: Object.values(scheduledUsers),
                            field: "scheduledUserIdForCalendar",
                            valueField: "scheduledUserIdForCalendar",
                            textField: "schedulerGroupName",
                        },
                    ]}

                />}
            </div>

            <div className='d-flex justify-content-between'>


                {!!selectedSlot && claim?.status_ID !== 7 ? (<>

                    <SelectedSlotToSchedule selectedInspector={selectedInspector} selectedSlot={selectedSlot} onSelectInspector={handleSelectInspector} inspectorIdError={inspectorIdError} inspectors={inspectors} />


                    <button style={claim?.status_ID !== 7 ? { display: "visible" } : { display: "none" }} className="btn-primary btn-sm me-1 btn mt-3" onClick={() => {
                        handleSchedule();
                    }} disabled={isPendingAddAvailability || !selectedSlot || isLoadingCheckSlotStatus}>
                        {(!isPendingAddAvailability) && "Schedule"}
                        {(isPendingAddAvailability) && <LoadingIndicator isLoading={isPendingAddAvailability || isLoadingCheckSlotStatus} showLabel={false} size='sm' />}
                    </button>

                </>) : <div></div>}
            </div>
            {showDeleteDialog && <Alert onClose={() => setShowDeleteDialog(false)} onSubmit={deleteSavedSlot} />}
            {statusAlertOptions.show && <Alert onClose={() => setStatusAlertOptions({ show: false, content: "" })} title='Schedule' subTitle={statusAlertOptions.content} onSubmit={() => {
                handleAddAvailability();
                setStatusAlertOptions({ show: false, content: "" })
            }} />}
        </div>
    );
})

