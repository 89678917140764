import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';

type Response = {
    message: 'Slot created successfully';
    data: null;
};

export type AddMultipleSlotPayload = {
    claimNo: string;
    isAvailable: boolean;
    schID: number;
    sDate: string;
    timeSlot: string;
    userGUID: string;
    claimId: number;
    userId: number;
    homeOwnerId: number;
    statusCode: string;
    dateSlots: Array<{
        FromDateTime: string;
        ToDateTime: string;
    }>;
    slotStatus: string;
};


export const useAddMultipleSlots = createMutation<Response, AddMultipleSlotPayload, AxiosError>({
    mutationFn: async variables =>
        axios({
            url: 'SchedulerAssignments/SaveTimeSlotMultiple',
            method: 'POST',
            data: variables,
        }).then(response => response.data),
});
