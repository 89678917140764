
import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';

type Response = {
    message: 'Slot created successfully';
    data: null;
};

type Payload = {
    inspectorQbaseId: string;
    assignmentNumber: string;
    inspectorName: string;
    scheduledStartDateTime: string;
    scheduledEndDateTime: string;
    taskID: number,
    claimId: number;
    claimNumber: string;
    homeownerEmail: string;
    homeownerPhone: string;
    homeownerName: string;
    scheduledOn: string
};

export const useAssignInspectorQB = createMutation<Response, Payload, AxiosError>({
    mutationFn: async payload =>
        axios({
            url: 'SchedulerAssignments/SendAssignmentInfoToQB',
            method: 'POST',
            data: { assignmentInfo: payload }
        }).then(response => response.data),
});
