import * as React from 'react';
import '@progress/kendo-date-math/tz/MST7MDT'
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useCurrentUser } from '../../../hooks/user-current-user.hook';
import { SLOT_TYPES, } from '../../../constants';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { Slot } from '../../../types/Slot';
import { useScheduleDeleteHomeownerSlot, useScheduledSlots } from '../../../api/homeowner';

import { Calendar } from './calendar';
import { Alert } from './delete-dialog';

dayjs.extend(utc);
dayjs.extend(timezone);



interface Props {
    homeownerId: number;
    claimId: number;
    onAddOrDeleteSchedule?: () => void;
    claimNumber: string;
    handleAddAvailability?: (start: string, end: string) => void;
}



export type HomeownerCalendarHandler = {
    reloadCalendar: () => void
}

export const HomeownerCalendar = React.forwardRef<HomeownerCalendarHandler, Props>(({ homeownerId, claimId, onAddOrDeleteSchedule, claimNumber, handleAddAvailability }, ref) => {

    const { userName } = useCurrentUser();
    const [slots, setSlots] = React.useState<{ [key: string]: Slot }>({})
    const { mutate: fetchScheduledSlots } = useScheduledSlots({
        onSuccess: (response: any) => setSlots(response.slots)
    })



    const { mutate: deleteScheduledSlot, isPending: isPendingDeleteScheduledSlot } = useScheduleDeleteHomeownerSlot()

    const selectedStartAndEndDatesRef = React.useRef<{ start: string, end: string }>({ start: null, end: null })

    const getSlots = () => {
        if (selectedStartAndEndDatesRef.current.start && selectedStartAndEndDatesRef.current.end) {
            fetchScheduledSlots({ homeownerId, claimId, SDate: selectedStartAndEndDatesRef.current.start, EDate: selectedStartAndEndDatesRef.current.end })
        }
    }



    React.useImperativeHandle(ref, () => {
        return {
            reloadCalendar: () => {
                getSlots()
            }
        };
    }, [slots, getSlots, fetchScheduledSlots,]);


    const backupSlot = React.useRef<{ [key: string]: Slot }>({})


    const slotToDelete = React.useRef<Slot>()
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

    const handleDeleteSlot = (slot: Slot) => {
        if (slot.statusCode === SLOT_TYPES.temp) {
            const slotsToDelete = { ...slots }
            delete slotsToDelete[slot.id]
            if (backupSlot.current[slot.id]) {
                slotsToDelete[slot.id] = backupSlot.current[slot.id]
                delete backupSlot.current[slot.id]
            }
            setSlots(slotsToDelete)
            return
        }

        slotToDelete.current = slot
        setShowDeleteDialog(true)
    }

    const deleteSavedSlot = () => {
        setShowDeleteDialog(false);
        const slot = slotToDelete.current
        deleteScheduledSlot({ claimNumber, slotId: slot.slotId, homeownerId, userId: homeownerId, userName, inspectorId: 0 }, {
            onSuccess: () => {
                const slotsToDelete = { ...slots }
                delete slotsToDelete[slot.id]
                setSlots(slotsToDelete)
                slotToDelete.current = null
                onAddOrDeleteSchedule?.()
            }
        })
    }

    const selectedSlots = React.useMemo(() => Object.values(slots || {}).filter(slot => slot.statusCode === SLOT_TYPES.temp), [slots])

    const selectedSlot = selectedSlots?.[0]

    return (
        <div>
            <div>
                <LoadingIndicator isLoading={isPendingDeleteScheduledSlot} />

                <Calendar showClaimNumber={false} selectedSlot={selectedSlot} source='homeowner' onChangeDate={(start, end) => {
                    selectedStartAndEndDatesRef.current = { start, end }
                    getSlots()
                }} slots={slots} onSelectSlot={({ start, end }) => handleAddAvailability(start.toISOString(), end.toISOString())} onDelete={handleDeleteSlot}
                    deleteScheduledSlot={false}
                />

            </div>
            {showDeleteDialog && <Alert onClose={() => setShowDeleteDialog(false)} onSubmit={deleteSavedSlot} />}

        </div >
    );
});