import * as React from 'react';
import {
    Scheduler,
    WeekView,
    SchedulerEditItem,
    SchedulerEditItemHandle,
    SchedulerEditItemProps,
    DayView,
    SchedulerEditSlotProps,
    SchedulerEditSlot,
    TimeHeaderCellProps,
    TimeHeaderCell,
    SchedulerItem,
    SchedulerProps,
} from '@progress/kendo-react-scheduler';




import '@progress/kendo-date-math/tz/MST7MDT'
import dayjs, { OpUnitType } from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Slot } from '../../../types/Slot';
import CloseIcon from '../../../images/close-icon';
import { AssignedUser } from '../../../api/claim';
import { SLOT_TYPES, TIME_ZONE } from '../../../constants';
import { useSLA } from '../../../api/common';
import { LoadingIndicator } from '../../../components/loading-indicator.component';

dayjs.extend(utc);
dayjs.extend(timezone);

type SlotConfig = {
    [key: string]: {
        title?: string;
        backgroundColor: string;
        borderColor?: string;
        borderStyle?: string;
        borderWidth: number;
        mobileClassName?: string
    }
}

const SLOT_CONFIG: SlotConfig = {
    [SLOT_TYPES.scheduled]: {
        backgroundColor: "white",
        borderColor: '#84d359',
        borderStyle: 'solid',
        borderWidth: 1,
        mobileClassName: 'in-scheduled'
    },
    [SLOT_TYPES.temp]: {
        backgroundColor: "#CFF6CF",
        borderColor: '#b4b6b4',
        borderStyle: 'dashed',
        borderWidth: 1,
        mobileClassName: 'in-available'
    },
    [SLOT_TYPES.unavailable]: {
        backgroundColor: "#dcdcdc",
        borderWidth: 1,
        mobileClassName: 'in-unavailable'
    },
    [SLOT_TYPES.availabilityReceived]: {

        backgroundColor: "white",
        borderColor: '#6553fc',
        borderStyle: 'solid',
        borderWidth: 1,
        mobileClassName: 'in-available'
    },
    [SLOT_TYPES.awaitingAvailability]: {
        backgroundColor: "white",
        borderColor: '#C4A484 ',
        borderStyle: 'solid',
        borderWidth: 1,
        mobileClassName: 'in-available'
    },
    default: {
        backgroundColor: "white",
        borderColor: 'red',
        borderStyle: 'solid',
        borderWidth: 1,
        mobileClassName: 'in-available'
    }
}




interface CustomEditItemProps extends SchedulerEditItemProps {
    onDelete: (slot: Slot) => void;
    hideTitle: boolean,
    handleClick: ({ start, end, resource }: {
        start: Date; end: Date, resource?:
        AssignedUser
    }) => void;
    claimStatus: string;
    timeSlotRange: number;
    hideDelete: boolean;
    showClaimNumber?: boolean;
}


const CustomEditItem = ({ onDelete, hideDelete, hideTitle, handleClick, claimStatus, timeSlotRange, showClaimNumber, ...props }: CustomEditItemProps) => {


    const ref = React.useRef<SchedulerEditItemHandle>(null);

    const slot = props.dataItem as Slot


    const slotConfig = SLOT_CONFIG[slot.statusCode] || SLOT_CONFIG.default

    let title = slot.title
    if (slot.statusCode === SLOT_TYPES.scheduled && claimStatus === SLOT_TYPES.rescheduled)
        title = "Previously Scheduled"
    else if (slot.statusCode === SLOT_TYPES.scheduled)
        title = "Details"


    return (
        <React.Fragment>
            <div style={{ background: "green" }}>

                <SchedulerEditItem
                    ref={ref}
                    {...props}
                    editable={false}
                    style={{ background: 'transparent' }}
                    item={(props) => {

                        return (
                            <div style={props.style}>


                                <SchedulerItem  {...props} onClick={(props) => {
                                    let start = props.target.props.start

                                    const resource = props.target.props?.group?.resources[0] as AssignedUser
                                    const mm = dayjs.utc(start).tz(TIME_ZONE).format('mm');
                                    if (mm === "30") {
                                        start = dayjs(start).subtract(30, 'minutes').toDate()
                                    }
                                    const end = dayjs(start).add(timeSlotRange, 'hours').toDate()
                                    handleClick({ start, end, resource })
                                }}>
                                    <div className='sheduler-Item'>

                                        <div className='slot-config' style={{
                                            background: slotConfig.backgroundColor,
                                            borderWidth: slotConfig.borderWidth,
                                            borderStyle: slotConfig.borderStyle,
                                            borderColor: slotConfig.borderColor,
                                        }} >
                                            <div className={slotConfig.mobileClassName} />

                                            {!hideDelete && <button className='slot-close-btn' onClick={(e) => {
                                                e.stopPropagation();
                                                onDelete(slot)
                                            }}
                                            ><CloseIcon size={10} /></button>}
                                            {!hideTitle && <span className='in-label'>{title}</span>}
                                            {showClaimNumber && !hideTitle && (props?.dataItem as Slot)?.claimNo && <div>{(props.dataItem as Slot).claimNo}</div>}
                                        </div>
                                    </div>
                                </SchedulerItem>
                            </div>
                        )
                    }}
                />
            </div>
        </React.Fragment>
    )
}


interface CustomEditSlotProps extends SchedulerEditSlotProps {
    handleClick?: ({ start, end, resource }: {
        start: Date; end: Date, resource?:
        AssignedUser
    }) => void;
    timeSlotRange: number
}

const CustomEditSlot = ({ handleClick, timeSlotRange, ...props }: CustomEditSlotProps) => {
    return (
        <React.Fragment>
            <div style={{ width: '100%', backgroundColor: 'white' }}>
                <SchedulerEditSlot
                    {...props}
                    onClick={(props) => {
                        let start = props.target.props.start

                        const resource = props.target.props?.group?.resources[0] as AssignedUser
                        const mm = dayjs.utc(start).tz(TIME_ZONE).format('mm');
                        if (mm === "30") {
                            start = dayjs(start).subtract(30, 'minutes').toDate()
                        }
                        const end = dayjs(start).add(timeSlotRange, 'hours').toDate()
                        handleClick({ start, end, resource })
                    }}
                />
            </div>
        </React.Fragment>
    )
}

const CustomSchedulerSlot = (props: TimeHeaderCellProps) => {


    return (
        <React.Fragment>
            <div style={{ backgroundColor: 'white', height: 20 }}>
                <TimeHeaderCell
                    {...props} />
            </div>
        </React.Fragment>
    )
}






interface Props extends SchedulerProps {
    slots: { [key: string]: Slot };
    showClaimNumber?: boolean;
    selectedSlot: Slot;
    onChangeDate: (start: string, end: string) => void;
    onDelete?: (slot: Slot) => void
    onSelectSlot?: ({ start, end, resource }: { start: Date; end: Date; resource: AssignedUser }) => void;
    onlyShowDay?: boolean;
    source: "inspector" | 'homeowner' | 'claim',
    claimStatus?: string;
    deleteScheduledSlot?: boolean;

}


export function Calendar({ selectedSlot, showClaimNumber = true, onChangeDate, slots, onDelete, onSelectSlot, onlyShowDay = false, claimStatus, source, deleteScheduledSlot = true, ...props }: Props) {

    const { data: timeSlotRangeData, isLoading: isLoadingTimeSlotRange } = useSLA({ variables: { key: 'TimeSlotRange' } })


    const timeSlotRange = Number(timeSlotRangeData?.sValue || 1)

    React.useEffect(() => {
        onDateChange()
    }, [])

    const dayRef = React.useRef(dayjs().tz(TIME_ZONE).toDate())
    const viewRef = React.useRef('day')


    const handleSlotClick = ({ start, end, resource }: { start: Date; end: Date; resource?: AssignedUser }) => {
        if (viewRef.current === "month")
            return
        onSelectSlot({ start, end, resource })
    }



    const onDateChange = () => {
        const dayStart = dayjs.utc(dayRef.current).tz(TIME_ZONE).startOf(viewRef.current as OpUnitType).toISOString()
        const dayEnd = dayjs.utc(dayRef.current).tz(TIME_ZONE).endOf(viewRef.current as OpUnitType).toISOString()
        onChangeDate(dayStart, dayEnd)
    }



    if (isLoadingTimeSlotRange) {
        return <LoadingIndicator isLoading />
    }

    return (
        <Scheduler
            timezone={TIME_ZONE}
            defaultDate={dayRef.current}
            editItem={(props) => {
                return <CustomEditItem {...props} hideDelete={!deleteScheduledSlot && props.dataItem.statusCode === SLOT_TYPES.scheduled} handleClick={handleSlotClick} timeSlotRange={timeSlotRange} claimStatus={claimStatus} hideTitle={source === "claim" && props.dataItem.statusId === SLOT_TYPES.scheduled} onDelete={onDelete}
                    showClaimNumber={showClaimNumber} />
            }}
            editSlot={(props) => <CustomEditSlot {...props} timeSlotRange={timeSlotRange} handleClick={handleSlotClick} />}

            data={Object.values(slots)}
            editable={{
                edit: false, remove: true, drag: false, resize: false, add: false, select: false
            }}
            defaultView={viewRef.current}
            onViewChange={(e) => {
                viewRef.current = e.value
                onDateChange()
            }}
            onDateChange={(event) => {
                dayRef.current = dayjs.utc(event.value).tz(TIME_ZONE).toDate()
                onDateChange()
            }}

            {...props}
        >
            <DayView
                workDayStart="08:00"
                workDayEnd="18:00"
                slotDuration={timeSlotRange * 60}
                numberOfDays={1}
                slotDivisions={1}
                startTime="07:00"
                timeHeaderCell={CustomSchedulerSlot}
                endTime="18:00"
                currentTimeMarker={false}

            />


            {!onlyShowDay && <WeekView
                workDayStart="08:00"
                workDayEnd="18:00"
                slotDuration={timeSlotRange * 60}
                slotDivisions={1}
                startTime="07:00"
                endTime="18:00"
                currentTimeMarker={false}
            />}



        </Scheduler>

    );
};