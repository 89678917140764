import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { TIME_ZONE } from '../../../constants';
import { bindCurrentTimeToDate, checkDateRelationToTodayInMst, getNextHourInMst, getUtcIntervalsForMst } from '../../../utils/timeUtils';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { AddMultipleSlotPayload, useAddMultipleSlots } from '../../../api/homeowner/use-add-multiple-slots';

interface Props {
    startTime?: string;
    endTime?: string;
    show: boolean;
    onClose: () => void;
    initialPayload: AddMultipleSlotPayload;
    onSuccess: () => void;
}

export function AddAvailabilityModal({ startTime, endTime, show, onClose, initialPayload, onSuccess }: Props) {
    const [date, setDate] = useState(startTime || dayjs.utc().tz(TIME_ZONE).toISOString());
    const [localStartTime, setLocalStartTime] = useState(startTime);
    const [localEndTime, setLocalEndTime] = useState(endTime);
    const [startTimeOptions, setStartTimeOptions] = useState([]);
    const [endTimeOptions, setEndTimeOptions] = useState([]);

    useEffect(() => {
        const dateRelation = checkDateRelationToTodayInMst(date);
        if (dateRelation !== 'previous') {
            const nextHour = dateRelation === 'today' ? getNextHourInMst(date) : 8;
            const startTimeSlots = getUtcIntervalsForMst(8, 17, nextHour, date);
            const endTimeSlots = getUtcIntervalsForMst(8, 18, nextHour, date);

            setStartTimeOptions(startTimeSlots);
            setEndTimeOptions(endTimeSlots);

            const startHour = nextHour < 8 ? 8 : nextHour;
            if (!startTime || !endTime) {
                const startDateToUpdate = dayjs.utc(date).tz(TIME_ZONE).hour(startHour).minute(0).second(0).millisecond(0);
                const endDateToUpdate = dayjs.utc(date).tz(TIME_ZONE).hour(startHour + 1).minute(0).second(0).millisecond(0);

                setLocalStartTime(startDateToUpdate.toISOString());
                setLocalEndTime(endDateToUpdate.toISOString());
            }
        }
    }, [startTime, endTime, date]);

    const handleDateChange = (updatedDate: Date) => {
        setDate(dayjs(bindCurrentTimeToDate(updatedDate)).tz(TIME_ZONE).toISOString());
    };

    const handleStartTimeChange = (option: { value: string; label: string; disabled: boolean }) => {
        if (option.disabled) return;
        setLocalStartTime(option.value);
        const endDate = dayjs.utc(option.value).tz(TIME_ZONE).add(1, 'hour').toISOString();
        setLocalEndTime(endDate);
    };

    const startTimeOption = startTimeOptions.find((option) => option.value === localStartTime);
    const endTimeOption = endTimeOptions.find((option) => option.value === localEndTime);

    const {
        mutate: addSlot, isPending: isPendingAddSlot
    } = useAddMultipleSlots({
        onSuccess: () => {
            onSuccess();
            onClose();
        }
    })

    const handleSave = () => {
        const payload = {
            ...initialPayload,
            sDate: dayjs.utc(localStartTime).tz(TIME_ZONE).format("YYYY-MM-DD"),
            slotStatus: "AVALBL",
            timeSlot: `${dayjs.utc(localStartTime).format("THH:mm:ss.SSS[Z]")}-${dayjs.utc(localEndTime).format("THH:mm:ss.SSS[Z]")}`,
            dateSlots: [
                {
                    FromDateTime: localStartTime,
                    ToDateTime: localEndTime,
                }
            ],
        };

        addSlot(payload as AddMultipleSlotPayload);
    };

    return (

        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title className='ti-font'>Add Time Slot</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 13, }}>
                    <b className='mb-0'>
                        INSTRUCTIONS
                    </b>
                    <p className='mb-0' style={{ color: '#999' }}>
                        Select the dates and times you&#39;re available for
                        an inspection with a Keystone Expert.
                    </p>
                    <p className='mb-0' style={{ color: '#999' }}>Click UPDATE to submit your availability. Keystone will review your selections and
                        confirm the inspection details shortly.</p>


                    <div className='d-flex w-100 gap-3 align-items-center'>
                        <div className='dateIcon'></div>
                        <label style={{ flex: 1 }}>
                            <DatePicker
                                selected={dayjs.utc(date).tz(TIME_ZONE).toDate()}
                                openToDate={dayjs.utc(date).tz(TIME_ZONE).toDate()}
                                value={dayjs.utc(date).tz(TIME_ZONE).format("MM/DD/YYYY")}
                                minDate={dayjs.utc().tz(TIME_ZONE).toDate()}
                                className="form-control ms-auto calendarBg"
                                onChange={handleDateChange}
                            />
                        </label>
                    </div>
                    <div className='d-flex w-100 gap-3 align-items-end '>
                        <div className='timeIcon mb-2'></div>
                        <label style={{ flex: 1 }}>
                            Start time:
                            <Select
                                value={startTimeOption}
                                options={startTimeOptions}
                                onChange={handleStartTimeChange}
                            />
                        </label>
                        <label style={{ flex: 1 }}>
                            End time:
                            <Select
                                value={endTimeOption}
                                options={endTimeOptions}
                                isDisabled
                            />
                        </label>
                    </div>
                    <div className='d-flex'>
                        <button className="btn btn-primary btn-md ms-auto mt-2" onClick={handleSave}>
                            {isPendingAddSlot ? <div className="spinner-border spinner-border-sm" /> : "Update"}
                        </button>
                    </div>
                </div>
            </Modal.Body>


        </Modal>)

}