import { useEffect, useRef, useState } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import './login.style.scss';
import SingleDropdown from '../../components/single-select-dropdown';
import { SendOtpPayload, SendOtpResponse, useSendMobileOtp } from '../../api/inspector';
import { InspectorVerifyOtp } from './inspector-verify-otp';



interface Props {
    onSendOtp: (payload: SendOtpPayload, onSuccess: (response: SendOtpResponse) => void, onError: () => void) => void;
    isPendingSendOtp: boolean;
    sendOtpPayload: SendOtpPayload;
    authType: 'login' | 'verifyOtp';
    setAuthType: (authType: 'login' | 'verifyOtp') => void
}

export const MobileLogin = ({ onSendOtp, isPendingSendOtp, sendOtpPayload, authType, setAuthType }: Props) => {
    const [error, setError] = useState('');

    const userGuidRef = useRef<string>()

    const handleSendOtp = async (values: { mobileNo: string; countryCode: string; userType: string }) => {
        onSendOtp({ ...values, userType: 'Inspector' }, (response) => {
            if (response.success) {
                userGuidRef.current = response?.result
                setAuthType('verifyOtp')
            } else {
                setError(response.errorMessage || 'Failed to send OTP');
            }
        }, () => { setError('Failed to send OTP'); });
    }

    const initialLoginData = {
        mobileNo: '',
        countryCode: '+1',
        userType: ''
    };

    const loginSchema = Yup.object().shape({
        mobileNo: Yup.string()
            .matches(/^\d{10}$/, 'Enter a valid 10 digit mobile number')
            .required('Mobile number is required'),
        countryCode: Yup.string().required('Country code is required'),
    });



    return (

        authType === 'verifyOtp' ?
            <InspectorVerifyOtp channel='mobile' sendOtpPayload={sendOtpPayload} userGuid={userGuidRef.current} resendOtp={onSendOtp} isPendingResendOtp={isPendingSendOtp} /> :
            <>
                {error && <div className="alert alert-danger">{error}</div>}
                <LoadingIndicator isLoading={isPendingSendOtp} />
                <Formik
                    initialValues={initialLoginData}
                    validationSchema={loginSchema}
                    onSubmit={handleSendOtp}
                >
                    {({ setFieldValue, values }) => (
                        <Form className="form-content">
                            <div className="gridMobile">
                                <div >
                                    <label htmlFor="countryCode">Country Code</label>
                                    <SingleDropdown
                                        className="customDropdown"
                                        dataitems={[
                                            { text: '+91', value: '+91' },
                                            { text: '+1', value: '+1' },
                                        ]}
                                        width='80px'
                                        height='37px'
                                        showDefaultItem={false}
                                        id="countryCode"
                                        value={values.countryCode}
                                        onchange={(value: string) => setFieldValue('countryCode', value)}
                                    />
                                </div>
                                <div >
                                    <label htmlFor="mobile">Mobile</label>
                                    <Field
                                        className="form-control form-control-md"
                                        name="mobileNo"
                                        placeholder="Enter mobile number"
                                    />

                                </div>

                            </div>
                            <div className="mt-2">
                                <small className="errorMsg">
                                    <ErrorMessage name="mobileNo" />
                                </small>
                            </div>
                            <button
                                id="btnSignIn"
                                className="btn btn-normal btn-block w-100 mt-2 text-uppercase"
                                type="submit"
                                disabled={isPendingSendOtp}
                            >
                                Send one time code
                            </button>
                        </Form>

                    )}
                </Formik>
            </>
    );
};

export default MobileLogin;
