import type { AxiosError } from 'axios';
import axios from 'axios';
import dayjs from 'dayjs';
import { createQuery } from 'react-query-kit';

type Params = {
    SDate: string,
    FDate: string
}


const formatResponse = (response: Scheduler[]) => {
    return response.map((item) => {

        let scheduledOnDate = null
        if (item.scheduleOn) {
            scheduledOnDate = new Date(dayjs(item.scheduleOn).get("year"), dayjs(item.scheduleOn).get("month"), dayjs(item.scheduleOn).get("date"))
        }

        let createdOnDate = null
        if (item.createdOn) {
            createdOnDate = new Date(dayjs(item.createdOn).get("year"), dayjs(item.createdOn).get("month"), dayjs(item.createdOn).get("date"))
        }

        return {
            ...item,
            scheduledOnDate,
            createdOnDate
        }
    })
}


export const useAgingReports = createQuery<Scheduler[], Params, AxiosError>({
    queryKey: ['agingReports'],
    fetcher: (params) =>
        axios.get('SchedulerAssignments/GetMetricsReport', { params }).then(response => formatResponse(response.data?.result || [])),
    refetchOnWindowFocus: false
});
