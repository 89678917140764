import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { TIME_ZONE } from '../constants';

dayjs.extend(utc);
dayjs.extend(timezone);

export function getUtcIntervalsForMst(startHourMST: number, endHourMST: number, nextHour: number, date: string) {
    const intervals = [];

    for (let hour = startHourMST; hour <= endHourMST; hour++) {
        const formattedDate = dayjs(date).tz(TIME_ZONE).set('hour', hour).set('minute', 0).set('second', 0).set('millisecond', 0);

        intervals.push({
            label: formattedDate.format('hh:mm A'),
            value: formattedDate.toISOString(),
            disabled: hour < nextHour,
        });
    }

    return intervals;
}

export function getNextHourInMst(date: string) {
    const nextHour = dayjs.utc(date).tz(TIME_ZONE).add(1, 'hour').startOf('hour');
    return nextHour.hour();
}

export function checkDateRelationToTodayInMst(date: string) {
    const inputDate = dayjs.utc(date).tz(TIME_ZONE).startOf('day');


    const today = dayjs.utc().tz(TIME_ZONE).startOf('day');



    if (inputDate.isSame(today)) {
        return 'today';
    } else if (inputDate.isBefore(today)) {
        return 'previous';
    } else {
        return 'future';
    }
}

export function isDateTimeOver(dateTime: string) {
    const inputDateTime = dayjs.utc(dateTime).tz(TIME_ZONE);
    const now = dayjs.utc().tz(TIME_ZONE);

    return inputDateTime.isBefore(now);
}

export function convertUtcToMst(utcDate: string) {
    return dayjs.utc(utcDate).tz('America/Denver').format('MM/DD/YYYY hh:mm A');
}

export function bindCurrentTimeToDate(date: Date) {
    const currentTime = dayjs();
    const updatedDate = dayjs(date)
        .hour(currentTime.hour())
        .minute(currentTime.minute())
        .second(currentTime.second())
        .millisecond(currentTime.millisecond());

    return updatedDate.toDate();
}
