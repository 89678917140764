import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import HomeOwnerLandingDetails from "../features/scheduler/home-owner-landing-details";
import { HeaderComponent } from "./header.component";
import { AuthorizeComponent } from "./authorize.component";
import { HomeOwnerLogin } from "../features/login/login-home-owner";
import HomeOwnerAvailability from "../features/scheduler/home-owner-availability";
import { useStorage } from "../hooks/use-storage";
import { StorageKey } from "../constants";
import { useEffect } from "react";

export function HomeOwnerRoutes() {
    const [userType] = useStorage(StorageKey.UserType, null);
    const nav = useNavigate()
    const url = useLocation()

    useEffect(() => {


        if (userType !== "homeowner") {
            if (!url.pathname.includes('homeowner-login'))
                nav("/homeowner/homeowner-login")
        }
    }, [userType])

    return (
        <AuthorizeComponent role='homeowner'>
            <HeaderComponent showMenu={false} disableLogoClick />
            <Routes>
                <Route
                    path="/landing-page"
                    element={<HomeOwnerLandingDetails />}
                />
                <Route
                    path="/homeowner-login/:id"
                    element={<HomeOwnerLogin />}
                />
                <Route
                    path="/homeowner-availability"
                    element={<HomeOwnerAvailability />}
                />

            </Routes>
        </AuthorizeComponent>)
}