import * as React from 'react';

import { Modal } from './modal';




import '@progress/kendo-date-math/tz/MST7MDT'
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AddAvailabilityPayload, useAddAvailabilitySlots, useRequestAvailability, useScheduleDeleteHomeownerSlot, useScheduledSlots, useScheduleHomeownerSlot } from '../../../api/homeowner';
import { Calendar } from './calendar';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { Slot } from '../../../types/Slot';
import { useCurrentUser } from '../../../hooks/user-current-user.hook';
import { useAssignInspectorQB, useInspectors } from '../../../api/inspector';
import { Alert } from './delete-dialog';
import { IInspector } from '../../../models/scheduler/inspector';
import { isEmpty } from 'lodash';
import { SLOT_TYPES, StorageKey, TIME_ZONE, WINDOWS_DOORS_API_URL } from '../../../constants';
import { Dialog } from '@progress/kendo-react-dialogs';
import { boolean } from 'yup';
import mobileImage from '../../../images/mobile-image.png';
import { AddAvailability } from './add-availability';
import { formatPhoneNumber } from '../utils';
import { convertToTimezone } from '../../../utils';
import SingleDropdown from '../../../components/single-select-dropdown';
dayjs.extend(utc);
dayjs.extend(timezone);

interface UserItemProps {
    title: string;
    data: string
}


export function UserItem({ title, data }: UserItemProps) {
    return (
        <div className='d-flex flex-column'>
            <small>
                {title}
            </small>
            <p style={{ fontWeight: 'bold' }}>
                {data}
            </p>
        </div>
    );
}

function UserDetails({ claim, onSuccessRequest, closeCalendar }: {
    claim: Scheduler;
    onSuccessRequest?: () => void;
    closeCalendar?: () => void

}) {
    const [showRequestAvailabilityStatus, setShowRequestAvailabilityStatus] = React.useState<boolean>(false);
    const [sendMessage, setSendMessage] = React.useState<boolean>(false);
    const { userName, fullName, userGUID, userID } = useCurrentUser();
    const [isRequestLoading, setIsRequestLoading] = React.useState<boolean>(false);


    React.useEffect(() => {
        //setIsRequestLoading(false);
    }, [isRequestLoading]);


    const onRequestSendHomeOwner = async (itemData: any) => {


        if (!isEmpty(itemData)) {

            const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));
            const url = `${WINDOWS_DOORS_API_URL}/api/SchedulerAssignments/RequestHomeownerAvailability?schedulerId=${userID}&claimId=${itemData.claimID}&claimNumber=${itemData.claimNo}&schedulerName=${fullName}&homeOwnerName=${itemData.homeOwner}&homeOwnerEmail=${itemData.homeowner_Email}&claimGUID=${itemData.claimGUID}&claimAddress=${itemData.address}`;
            setIsRequestLoading(true);

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...(authState && { Authorization: `Bearer ${authState}` }),
                },
            };
            await fetch(url, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json(); // Adjust based on expected response type
                })
                .then(data => {
                    setSendMessage(true);
                    setIsRequestLoading(false);
                    setShowRequestAvailabilityStatus(true);
                    onSuccessRequest?.()
                })
                .catch(error => {
                    console.log(error)
                    setIsRequestLoading(false);
                })
        };
    }

    const sendOtp = async (itemData: any) => {
        try {
            // /itemData.homeowner_Email
            if (!isEmpty(itemData?.homeowner_Email)) {
                const emailContent = { email: itemData.homeowner_Email, userType: 'Homeowner' }
                const response = await fetch(`${WINDOWS_DOORS_API_URL}/api/SchedulerAssignments/EmailVerificationAndSendOTP`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(emailContent),
                });
                const data: any = await response.json();

                if (data.success) {
                    setSendMessage(true);
                    setIsRequestLoading(false);
                    setShowRequestAvailabilityStatus(true);
                    console.log("otp sent successfully!");
                } else {
                    console.log(data.message || 'Failed to send OTP');
                }
            } else {
                console.log("Email Id not found!!");
                setSendMessage(false);
                setIsRequestLoading(false);
            }
        } catch (ex) {
            console.log('Failed to send OTP');
            setSendMessage(false);
            setIsRequestLoading(false);
        }
    }
    const toggleDialog = () => {
        setShowRequestAvailabilityStatus(false);
        closeCalendar?.()

    };
    return (
        <>
            <div className="d-flex gap-5">
                <UserItem title='Insured' data={claim.homeOwner} />
                <UserItem title='Address' data={claim.address} />
                {claim.homeOwner_Phone && <UserItem title='Phone' data={formatPhoneNumber(claim.homeOwner_Phone)} />}
                <button className='btn btn-outline-primary ms-auto btn-sm text-capitalize' style={{ height: 40 }} onClick={() => onRequestSendHomeOwner(claim)}>
                    {isRequestLoading ? <LoadingIndicator size='sm' showLabel={false} isLoading={isRequestLoading} /> : "REQUEST AVAILABILITY"}</button>
            </div >
            {
                showRequestAvailabilityStatus &&
                <Dialog width="430px" title={" "} onClose={toggleDialog} style={{ borderRadius: "10px" }} className="delete-dialog">

                    <div className='messageGroup'>
                        <div >
                            <img src={mobileImage} />
                        </div>
                        <div >
                            <h6 style={{ textAlign: "center" }} >Availability request has been
                                sent to the Insured</h6>
                        </div>
                    </div>

                </Dialog>
            }
        </>
    )
}


function SelectedSlotToSchedule({ selectedSlot, onSelectInspector, inspectorIdError, inspectors }: {
    selectedSlot: Slot;
    onSelectInspector: (inspectorId: string) => void;
    inspectorIdError: string;
    inspectors: IInspector[]
}) {


    const [selectedInspectorId, setSelectedInspectorId] = React.useState('-1')



    const formattedInspectorsForDropdown = React.useMemo(() => {
        return inspectors.map((inspector) => ({ text: inspector.inspector_Name, value: inspector.userID?.toString() }))
    }, [inspectors])



    return (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: 20, flex: 1, justifyContent: 'flex-end', marginRight: 30, alignItems: 'center' }}>
                <SingleDropdown
                    className="customDropdown"
                    id="inspector"
                    onchange={(inspectorId: string) => {
                        onSelectInspector(inspectorId);

                        setSelectedInspectorId(inspectorId)
                    }}
                    value={selectedInspectorId}
                    dataitems={formattedInspectorsForDropdown}
                />
                <div>{dayjs.utc(selectedSlot.start).tz(TIME_ZONE).format('dddd, MMMM DD, YYYY')}</div>
                <div style={{ borderWidth: "0 0 1px", borderStyle: 'solid', borderColor: 'black', height: 22 }}>{dayjs.utc(selectedSlot.start).tz(TIME_ZONE).format('hh:ss A')}</div>
                <div style={{ borderWidth: "0 0 1px", borderStyle: 'solid', borderColor: 'black', height: 22 }}>{dayjs.utc(selectedSlot.end).tz(TIME_ZONE).format('hh:ss A')}</div>
            </div>
            {inspectorIdError && <span style={{ color: 'red' }}>{inspectorIdError}</span>}
        </div>
    )
}





interface Props {
    show: boolean;
    onClose: () => void;
    claim: Scheduler;
    setShowRequestAvailabilityStatus: (val: boolean) => void;
    onAddOrDeleteSchedule: () => void;
}


export function ScheduleHomeowner({ show, onClose, claim, setShowRequestAvailabilityStatus, onAddOrDeleteSchedule }: Props) {


    const { userID, userName, fullName } = useCurrentUser();
    const [slots, setSlots] = React.useState<{ [key: string]: Slot }>({})
    const { mutate: fetchScheduledSlots } = useScheduledSlots({
        onSuccess: (response) => setSlots(response.slots)
    })


    const { mutate: addAvailability, isPending: isPendingAddAvailability } = useAddAvailabilitySlots({
        onSuccess: () => {
            onAddOrDeleteSchedule?.()
            onClose()
        }
    })

    const { mutate: deleteScheduledSlot, isPending: isPendingDeleteScheduledSlot } = useScheduleDeleteHomeownerSlot()

    const selectedStartAndEndDatesRef = React.useRef<{ start: string, end: string }>({ start: null, end: null })

    const getSlots = (homeownerId: number) => {
        if (selectedStartAndEndDatesRef.current.start && selectedStartAndEndDatesRef.current.end) {
            fetchScheduledSlots({ homeownerId, claimId: claim.claimID, SDate: selectedStartAndEndDatesRef.current.start, EDate: selectedStartAndEndDatesRef.current.end })
        }
    }

    const backupSlot = React.useRef<{ [key: string]: Slot }>({})


    const slotToDelete = React.useRef<Slot>()
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

    const handleDeleteSlot = (slot: Slot) => {
        if (slot.statusCode === SLOT_TYPES.temp) {
            const slotsToDelete = { ...slots }
            delete slotsToDelete[slot.id]
            if (backupSlot.current[slot.id]) {
                slotsToDelete[slot.id] = backupSlot.current[slot.id]
                delete backupSlot.current[slot.id]
            }
            setSlots(slotsToDelete)
            return
        }

        slotToDelete.current = slot
        setShowDeleteDialog(true)
    }

    const deleteSavedSlot = () => {
        setShowDeleteDialog(false);
        const slot = slotToDelete.current
        deleteScheduledSlot({ claimNumber: claim.claimNo, slotId: slot.slotId, homeownerId: claim.homeowner_ID, userId: userID, userName, inspectorId: 0 }, {
            onSuccess: () => {
                const slotsToDelete = { ...slots }
                delete slotsToDelete[slot.id]
                setSlots(slotsToDelete)
                slotToDelete.current = null
                onAddOrDeleteSchedule?.()
            }
        })
    }


    const handleSelectSlot = ({ start, end }: { start: Date; end: Date; }) => {






        const isPrevDay = convertToTimezone(start).isBefore(convertToTimezone(new Date()), 'day')
        if (isPrevDay) return


        const slot = {
            id: dayjs(start).toISOString(),
            title: '',
            start,
            end,
            statusCode: SLOT_TYPES.temp
        }




        const prevSlots = { ...slots }
        if (prevSlots[slot.id] && prevSlots[slot.id].statusCode !== SLOT_TYPES.temp) {
            backupSlot.current[slot.id] = prevSlots[slot.id]
        }

        if (prevSlots[slot.id] && prevSlots[slot.id].statusCode === SLOT_TYPES.temp) {
            return
        }

        setSlots(prevSlot => ({ ...prevSlot, [slot.id]: slot }))
    }



    const selectedSlots = React.useMemo(() => Object.values(slots || {}).filter(slot => slot.statusCode === SLOT_TYPES.temp), [slots])

    const selectedSlot = selectedSlots?.[0]





    const { mutate: assignInspectorToQB } = useAssignInspectorQB()

    const { data: inspectors } = useInspectors({
        variables: { claimID: claim?.claimID }
    })


    const handleAddAvailability = (statusCode: string) => {

        if (statusCode === SLOT_TYPES.scheduled) {

            const selectedInspector = inspectors.find(inspector => inspector.userID === Number(selectedInspectorIdRef.current))

            if (!!selectedInspector.inspectorQuickBaseId && !!claim.keyStoneNo) {

                const params = {
                    assignmentNumber: claim.keyStoneNo,
                    taskID: claim.taskID ? Number(claim.taskID) : 0,
                    inspectorQbaseId: selectedInspector.inspectorQuickBaseId,
                    inspectorName: selectedInspector.inspector_Name,
                    scheduledOn: `${convertToTimezone(selectedSlot.start).format('MM/DD/YYYY')} (${convertToTimezone(selectedSlot.start).format('hh:mmA')} - ${convertToTimezone(selectedSlot.end).format('hh:mmA')})`,
                    scheduledStartDateTime: convertToTimezone(selectedSlots[0].start).format(),
                    scheduledEndDateTime: convertToTimezone(selectedSlots[0].end).format(),
                    claimId: claim.claimID,
                    claimNumber: claim.claimNo,
                    homeownerEmail: claim.homeowner_Email,
                    homeownerPhone: claim.homeOwner_Phone,
                    homeownerName: claim.homeOwner
                }
                assignInspectorToQB(params)
            }
        }


        const payload = selectedSlots?.map((slot): AddAvailabilityPayload => (
            { fromDateTime: slot.start.toISOString(), toDateTime: slot.end.toISOString(), claimID: claim.claimID, homeOwnerID: claim.homeowner_ID, slotStatusCode: statusCode, schedulerID: userID, inspectorID: Number(selectedInspectorIdRef.current || 0) }
        ))
        addAvailability({ payload, params: { claimNumber: claim.claimNo } })
    }

    const selectedInspectorIdRef = React.useRef<string>()

    const [inspectorIdError, setInspectorIdError] = React.useState<string>("")

    const handleSelectInspector = (inspectorId: string) => {
        selectedInspectorIdRef.current = inspectorId
        setInspectorIdError("")
    }





    return (
        <Modal show={show} onClose={onClose} renderActions={() =>
            <div className='d-flex justify-content-between align-items-center'>
                <div>


                    {
                        selectedSlots?.length > 0 && <AddAvailability isLoading={isPendingAddAvailability} onAddAvailability={handleAddAvailability} />
                    }
                </div>

                {selectedSlots?.length === 1 && !!selectedSlot && <>
                    <SelectedSlotToSchedule selectedSlot={selectedSlot} onSelectInspector={handleSelectInspector} inspectorIdError={inspectorIdError} inspectors={inspectors} />
                    <button className="btn-primary btn-sm me-1 btn" onClick={() => {
                        if (!selectedInspectorIdRef.current) {
                            setInspectorIdError("Please select an inspector")
                            return
                        }

                        handleAddAvailability(SLOT_TYPES.scheduled)
                    }}
                        disabled={isPendingAddAvailability || !selectedSlot}>
                        {!isPendingAddAvailability && "Schedule"}
                        {isPendingAddAvailability && <LoadingIndicator isLoading={isPendingAddAvailability} showLabel={false} size='sm' />}
                    </button>
                </>}
            </div>
        }>
            <div>
                <LoadingIndicator isLoading={isPendingDeleteScheduledSlot} />
                <UserDetails claim={claim} onSuccessRequest={() => {
                    onAddOrDeleteSchedule?.();

                }} closeCalendar={() => onClose()} />
                <Calendar selectedSlot={selectedSlot} source='homeowner' onChangeDate={(start, end) => {
                    selectedStartAndEndDatesRef.current = { start, end }
                    getSlots(claim.homeowner_ID)
                }} slots={slots} onSelectSlot={handleSelectSlot} onDelete={handleDeleteSlot} claimStatus={claim.statusCode} />

            </div>
            {showDeleteDialog && <Alert onClose={() => setShowDeleteDialog(false)} onSubmit={deleteSavedSlot} />}

        </Modal>
    );
};